import React, { Component, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import debounce from 'lodash/debounce';

import RewardsStyled from './Rewards.style';

import Heading from '../Heading/Heading';
import RewardsSlider from '../RewardsSlider/RewardsSlider';
import RewardsList from '../RewardsList/RewardsList';
import ModalController from '../ModalController/ModalController';
import ModalTerms from '../ModalTerms/ModalTerms';

import config from './config.js';

const messages = defineMessages({
  title1: {
    id: 'rewards.title1',
    defaultMessage: 'IN-GAME',
  },
  title2: {
    id: 'rewards.title2',
    defaultMessage: 'REWARDS',
  },
  subtitle: {
    id: 'rewards.subtitle',
    defaultMessage:
      'Every tab code unlocks rewards. Enter unlimited codes to keep redeeming in-game Boost.',
  },
  linkTerms: {
    id: 'rewards.linkTerms',
    defaultMessage: 'Click for terms.',
  },
});

class Rewards extends Component {
  state = {
    viewportWidth: 0,
  };

  componentDidMount() {
    const isClient = typeof window !== 'undefined';

    if (isClient) {
      this.updateWindowDimensions();
      window.addEventListener(
        'resize',
        debounce(this.updateWindowDimensions, 150),
      );
    }
  }

  componentWillUnmount() {
    const isClient = typeof window !== 'undefined';

    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
  };

  render() {
    const { viewportWidth } = this.state;
    const { intl } = this.props;

    const packsConfig = config.packs;

    const packs = packsConfig.map(item => {
      if (item.slides.length > 5) {
        return <RewardsSlider data={item} key={item.id} />;
      }
      if (item.slides.length === 5) {
        return viewportWidth < 1024 ? (
          <RewardsSlider data={item} key={item.id} />
        ) : (
          <RewardsList data={item} key={item.id} />
        );
      }
      if (item.slides.length === 3) {
        return viewportWidth < 460 ? (
          <RewardsSlider data={item} key={item.id} />
        ) : (
          <RewardsList data={item} key={item.id} />
        );
      }
      if (item.slides.length === 1) {
        return <RewardsList data={item} key={item.id} />;
      }
      return null;
    });

    const chainExclusivesConfig =
      intl.locale === 'en-US' ? config.chainExclusives : [];

    const chainExclusives = chainExclusivesConfig.map(item => {
      if (item.slides.length > 5) {
        return <RewardsSlider data={item} key={item.id} />;
      }
      if (item.slides.length === 5) {
        return viewportWidth < 1024 ? (
          <RewardsSlider data={item} key={item.id} />
        ) : (
          <RewardsList data={item} key={item.id} />
        );
      }
      if (item.slides.length === 3) {
        return viewportWidth < 460 ? (
          <RewardsSlider data={item} key={item.id} />
        ) : (
          <RewardsList data={item} key={item.id} />
        );
      }
      if (item.slides.length === 1) {
        return <RewardsList data={item} key={item.id} />;
      }
      return null;
    });
    return (
      <RewardsStyled id="rewards">
        <div className="Rewards__inner">
          <div className="Rewards__header">
            <Heading className="Rewards__heading" as="h3" white>
              <FormattedMessage {...messages.title1} />
              <FormattedMessage {...messages.title2} />
            </Heading>

            <div className="Rewards__subtitle">
              <FormattedMessage {...messages.subtitle} />{' '}
              <ModalController style={{ display: 'inline-block' }}>
                {(isOpen, handleToggle) => (
                  <Fragment>
                    <button
                      className="Rewards__link"
                      type="button"
                      onClick={handleToggle}
                    >
                      <FormattedMessage {...messages.linkTerms} />
                    </button>

                    {isOpen && <ModalTerms onClose={handleToggle} />}
                  </Fragment>
                )}
              </ModalController>
            </div>
          </div>

          {packs}
          {chainExclusives}
        </div>
      </RewardsStyled>
    );
  }
}

Rewards.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Rewards);
