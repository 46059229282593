import React, { Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import GrandPrizeStyled from './GrandPrize.style';
import imgXbox from '../../images/grandprize-prize-xbox.png';
import imgXboxGb from '../../images/grandprize-prize-xbox-en-GB.png';
import imgXboxDe from '../../images/grandprize-prize-xbox-de-DE.png';

import imgGear5 from '../../images/grandprize-prize-games.png';
import imgGear5Gb from '../../images/grandprize-prize-games-en-GB.png';
import imgGear5De from '../../images/grandprize-prize-games-de-DE.png';

import Heading from '../Heading/Heading';
import Carousel from '../Carousel/Carousel';

import config from './config';

const messages = defineMessages({
  title1: {
    id: 'grandPrize.title1',
    defaultMessage: 'RAPTOR',
    description: 'Title 1',
  },
  title2: {
    id: 'grandPrize.title2',
    defaultMessage: 'GRAND PRIZE',
    description: 'Title 2',
  },
  title1GbMx: {
    id: 'grandPrize.title1GbMx',
    defaultMessage: 'WINNERS',
  },
  title2GbMx: {
    id: 'grandPrize.title2GbMx',
    defaultMessage: 'EVERY DAY',
  },
  subtitle: {
    id: 'grandPrize.subtitle',
    defaultMessage:
      'Every tab code earns entry to win. Built by Addictive Desert Designs, the Gears 5 + Rockstar Energy special edition Ford F-150 Raptor is ready to crush anything.',
    description: 'Subtitle',
  },
  winTitle: {
    id: 'grandPrize.winTitle',
    defaultMessage: 'WINNERS EVERY DAY',
    description: 'Winners Title',
  },
  winSubTitle: {
    id: 'grandPrize.winSubTitle',
    defaultMessage: 'Every tab code earns entry to win daily!',
    description: 'Winners SubTitle',
  },
  prizeTitle1: {
    id: 'grandPrize.prizeTitle1',
    defaultMessage: 'LIMITED EDITION XBOX ONE X BUNDLE',
    description: 'Prize Title 1',
  },
  prizeTitle2: {
    id: 'grandPrize.prizeTitle2',
    defaultMessage: 'GEARS 5 ULTIMATE EDITION GAMES',
    description: 'Prize Title 2',
  },
});

const GrandPrize = ({ intl }) => {
  const localeConfig = config[intl.locale] || config[Object.keys(config)[0]];

  const prizeXbox =
    {
      'en-US': imgXbox,
      'en-CA': imgXbox,
      'en-GB': imgXboxGb,
      'de-DE': imgXboxDe,
      'es-MX': imgXbox,
    }[intl.locale] || imgXbox;

  const prizeGears5 =
    {
      'en-US': imgGear5,
      'en-CA': imgGear5,
      'en-GB': imgGear5Gb,
      'de-DE': imgGear5De,
      'es-MX': imgGear5,
    }[intl.locale] || imgGear5;

  return (
    <GrandPrizeStyled id="prizes">
      <div className="GrandPrize__inner">
        <div className="GrandPrize__header">
          <Heading className="GrandPrize__heading" as="h3">
            {intl.locale === 'en-GB' || intl.locale === 'es-MX' ? (
              <Fragment>
                <FormattedMessage {...messages.title1GbMx} />
                <FormattedMessage {...messages.title2GbMx} />
              </Fragment>
            ) : (
              <Fragment>
                <FormattedMessage {...messages.title1} />
                <FormattedMessage {...messages.title2} />
              </Fragment>
            )}
          </Heading>

          <div className="GrandPrize__subtitle">
            <FormattedMessage {...messages.subtitle} />
            <sup>*</sup>
          </div>
        </div>
      </div>

      {intl.locale === 'en-GB' || intl.locale === 'es-MX' ? null : (
        <Fragment>
          <div className="GrandPrize__slider">
            <Carousel slides={localeConfig.truckGallery} />
          </div>

          <h4 className="GrandPrize__winTitle">
            <FormattedMessage {...messages.winTitle} />
          </h4>

          <p className="GrandPrize__winSubTitle">
            <FormattedMessage {...messages.winSubTitle} />
            <sup>*</sup>
          </p>
        </Fragment>
      )}

      <div className="GrandPrize__prizes">
        <div className="GrandPrize__prize">
          <img className="GrandPrize__prizeImg" src={prizeXbox} alt="" />
          <h5 className="GrandPrize__prizeTitle">
            <FormattedMessage {...messages.prizeTitle1} />
          </h5>
        </div>
        <div className="GrandPrize__prize">
          <img className="GrandPrize__prizeImg" src={prizeGears5} alt="" />
          <h5 className="GrandPrize__prizeTitle">
            <FormattedMessage {...messages.prizeTitle2} />
          </h5>
        </div>
      </div>
    </GrandPrizeStyled>
  );
};

GrandPrize.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(GrandPrize);
