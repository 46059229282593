import styled from 'styled-components';
import * as defaultTheme from '../variables';

import GrandprizeBg from '../../images/grandprize-bg.jpg';

const GrandPrizeStyled = styled.section`
  padding: ${p => p.theme.space * 6}px 0;
  background-image: url(${GrandprizeBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .GrandPrize__inner {
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;

    @media (${p => p.theme.smMin}) {
      padding: 0 ${p => p.theme.space * 2}px;
    }
  }

  .GrandPrize__header {
    @media (${p => p.theme.smMin}) {
      display: flex;
      align-items: center;
    }

    @media (${p => p.theme.lgMin}) {
      align-items: flex-end;
    }
  }

  .GrandPrize__heading {
    flex-shrink: 0;
  }

  .GrandPrize__subtitle {
    padding: 20px;
    line-height: 14px;

    @media (${p => p.theme.smMin}) {
      max-width: 430px;
      margin-left: ${p => p.theme.space * 7}px;
      padding: 0;
      line-height: 24px;
    }

    sup {
      line-height: 1;
    }
  }

  .GrandPrize__slider {
    margin-top: ${p => p.theme.space * 3}px;

    @media (${p => p.theme.smMin}) {
      margin-top: ${p => p.theme.space * 6}px;
    }

    @media (${p => p.theme.lgMin}) {
      margin-top: ${p => p.theme.space * 7}px;
    }
  }

  .GrandPrize__winTitle {
    margin-bottom: ${p => p.theme.space}px;
    font-family: 'AzbukaPro';
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    line-height: 28px;

    @media (${p => p.theme.smMin}) {
      margin-bottom: ${p => p.theme.space * 2}px;
    }

    @media (${p => p.theme.lgMin}) {
      font-size: 31px;
    }
  }

  .GrandPrize__winSubTitle {
    margin: 0;
    text-align: center;
  }

  .GrandPrize__prizes {
    margin-top: ${p => p.theme.space * 4}px;
    text-align: center;

    @media (${p => p.theme.smMin}) {
      display: flex;
      justify-content: center;
      margin-top: ${p => p.theme.space * 6}px;
    }
  }

  .GrandPrize__prizeImg {
    width: 100%;
    max-width: 420px;

    @media (${p => p.theme.lgMin}) {
      width: 100%;
      max-width: 560px;
    }
  }

  .GrandPrize__prizeTitle {
    margin-top: ${p => p.theme.space}px;
    margin-bottom: 0;
    font-family: 'AzbukaPro';
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    line-height: 20px;
  }
`;

GrandPrizeStyled.defaultProps = {
  theme: defaultTheme,
};

export default GrandPrizeStyled;
