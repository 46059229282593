import React from 'react';
import cx from 'classnames';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl } from 'gatsby-plugin-intl';

import HeroStyled from './Hero.style';
import config from './config';

import CodeForm from '../CodeForm/CodeForm';

const messages = defineMessages({
  title1: {
    id: 'hero.title1',
    defaultMessage: 'GEAR UP',
    description: 'Title 1',
  },
  title2: {
    id: 'hero.title2',
    defaultMessage: 'WITH EVERY CAN',
    description: 'Title 2',
  },
  subtitle: {
    id: 'hero.subtitle',
    defaultMessage:
      'Enter tab codes to Unlock In-Game Items, Win Prize Entries and Play Gears 5 with Xbox Game Pass Access*.',
    description: 'Subtitle',
  },
});

const Hero = ({ intl }) => {
  const localeConfig = config[intl.locale] || config[Object.keys(config)[0]];

  return (
    <HeroStyled className={cx({ '--de': intl.locale === 'de-DE' })}>
      <div className="Hero__inner">
        <h1 className="Hero__title">
          <FormattedMessage {...messages.title1} />
          <p>
            <FormattedMessage {...messages.title2} />
          </p>
        </h1>
        <p className="Hero__subtitle">
          <FormattedMessage {...messages.subtitle} />
        </p>

        <img
          className={cx('Hero__cans', { '--de': intl.locale === 'de-DE' })}
          src={localeConfig.cans.src}
          alt={localeConfig.cans.alt}
        />

        <CodeForm />
      </div>
    </HeroStyled>
  );
};

export default injectIntl(Hero);
