import styled from 'styled-components';
import * as defaultTheme from '../variables';

const TruncateTextStyled = styled.span`
  .TruncateText__more {
    color: ${p => p.theme.blue};
    cursor: pointer;
    transition: color ${p => p.theme.transitionFast};

    &:hover {
      color: ${p => p.theme.darkBlue};
    }
  }
`;

TruncateTextStyled.defaultProps = {
  theme: defaultTheme,
};

export default TruncateTextStyled;
