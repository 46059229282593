import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RewardsSliderStyled from './RewardsSlider.style';

import TruncateText from '../TruncateText/TruncateText';
import Carousel from '../Carousel/Carousel';

const RewardsSlider = ({ data, title, light }) => {
  const slides = data.slides.map((slide, index) => {
    return {
      src: slide.src,
      alt: slide.alt,
      overlay: (
        <div
          className="RewardsSlider__overlay"
          title={slide.title.props.defaultMessage}
        >
          <div className="RewardsSlider__sliderTitle">
            <TruncateText length={25} ellipsis>
              {slide.title}
            </TruncateText>
          </div>
          <div className="RewardsSlider__sliderNum">
            {index + 1} / {data.slides.length}
          </div>
        </div>
      ),
    };
  });

  return (
    <RewardsSliderStyled>
      {title && (
        <div className="RewardsSlider__title">
          <h4 className="RewardsSlider__num">
            {data.title}
            <i />
            <i />
            <i />
            <i />
          </h4>
          <p className="RewardsSlider__desc --mobile">
            <TruncateText intlMessage={data.desc} length={100} />
          </p>
          <p className="RewardsSlider__desc --desktop">
            <TruncateText intlMessage={data.desc} />
          </p>
        </div>
      )}

      <Carousel customWidth={[138, 185, 216]} slides={slides} light={light} />
    </RewardsSliderStyled>
  );
};

RewardsSlider.propTypes = {
  title: PropTypes.bool,
  light: PropTypes.bool,
};

RewardsSlider.defaultProps = {
  title: true,
  light: true,
};

export default RewardsSlider;
