import React from 'react';

import cansUs from '../../images/hero-cans-en-US.png';
import cansCa from '../../images/hero-cans-en-CA.png';
import cansGb from '../../images/hero-cans-en-GB.png';
import cansDe from '../../images/hero-cans-de-DE.png';
import cansMx from '../../images/hero-cans-es-MX.png';

const config = {
  'en-US': {
    cans: {
      src: cansUs,
      alt: '',
    },
  },
  'en-CA': {
    cans: {
      src: cansCa,
      alt: '',
    },
  },
  'en-GB': {
    cans: {
      src: cansGb,
      alt: '',
    },
  },
  'de-DE': {
    cans: {
      src: cansDe,
      alt: '',
    },
  },
  'es-MX': {
    cans: {
      src: cansMx,
      alt: '',
    },
  },
};

export default config;
