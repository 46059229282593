import styled from 'styled-components';
import * as defaultTheme from '../variables';

import HeroBgMobile from '../../images/hero-bg-mobile.jpg';
import HeroBgMobileDe from '../../images/hero-bg-mobile-de.jpg';

import HeroBgDesktop from '../../images/hero-bg-desktop.jpg';
import HeroBgDesktopDe from '../../images/hero-bg-desktop-de.jpg';

const HeroStyled = styled.section`
  position: relative;
  background-repeat: no-repeat;

  @media (${p => p.theme.xsMax}) {
    margin-top: ${p => p.theme.space * 5}px;
    padding-bottom: ${p => p.theme.space * 5}px;
    background-image: url(${HeroBgMobile});
    background-size: 100% auto;

    &.--de {
      background-image: url(${HeroBgMobileDe});
    }
  }

  @media (${p => p.theme.smMin}) {
    height: 475px;
    margin-top: ${p => p.theme.space * 8}px;
    background-image: url(${HeroBgDesktop});
    background-position: -120px 65%;
    background-size: 150%;

    &.--de {
      background-image: url(${HeroBgDesktopDe});
      background-position: 0 30%;
      background-size: 130%;
    }
  }

  @media (${p => p.theme.mdMin}) {
    background-position: -120px 55%;
    background-size: 140%;

    &.--de {
      background-size: 100%;
    }
  }

  @media (${p => p.theme.lgMin}) {
    height: 545px;
    background-position: -70px 55%;
    background-size: 130%;

    &.--de {
      background-position: 0 35%;
    }
  }

  @media (${p => p.theme.xlMin}) {
    background-position: 0 55%;
    background-size: cover;
  }

  .Hero__inner {
    display: flex;
    flex-direction: column;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 0 ${p => p.theme.space * 3}px;

    @media (${p => p.theme.xsMax}) {
      align-items: center;
    }

    @media (${p => p.theme.smMin}) {
      justify-content: flex-end;
      height: 100%;
      padding-bottom: 20px;
    }
  }

  .Hero__title {
    margin: 0;
    padding-top: 35%;
    color: ${p => p.theme.red};
    font-family: 'MrBlack';
    font-size: 56px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 0 10px ${p => p.theme.textColorBlack};
    -webkit-font-smoothing: initial;
    -webkit-transform-style: preserve-3d;

    @media (min-width: 440px) {
      padding-top: 40%;
    }

    @media (${p => p.theme.smMin}) {
      font-size: 66px;
    }

    @media (${p => p.theme.lgMin}) {
      font-size: 74px;
      letter-spacing: 1.14px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: ${p => p.theme.space * 6}px;
      color: ${p => p.theme.textColorWhite};

      @media (${p => p.theme.xsMax}) {
        font-size: 34px;
      }

      @media (${p => p.theme.smMin}) {
        margin-left: 0;
        font-size: 44px;
      }

      @media (${p => p.theme.lgMin}) {
        font-size: 50px;
      }
    }
  }

  .Hero__subtitle {
    max-width: 350px;
    padding-top: 10%;
    color: ${p => p.theme.textColorWhite};
    font-size: 15px;
    line-height: 1.3;
    text-shadow: 0 0 5px ${p => p.theme.textColorBlack},
      1px 1px 2px ${p => p.theme.textColorBlack},
      2px 2px 2px ${p => p.theme.textColorBlack},
      3px 3px 2px ${p => p.theme.textColorBlack},
      -1px -1px 2px ${p => p.theme.textColorBlack},
      -2px -2px 2px ${p => p.theme.textColorBlack},
      -3px -3px 2px ${p => p.theme.textColorBlack};

    @media (${p => p.theme.xsMax}) {
      text-align: center;
    }

    @media (min-width: 440px) {
      padding-top: 8%;
    }

    @media (min-width: 520px) {
      padding-top: 10%;
    }

    @media (min-width: 640px) {
      padding-top: 13%;
    }

    @media (${p => p.theme.smMin}) {
      margin-top: 8px;
      margin-bottom: ${p => p.theme.space * 3}px;
      padding-top: 0;
    }
  }

  .Hero__cans {
    @media (${p => p.theme.xsMax}) {
      margin: ${p => p.theme.space * 2}px 0;
    }

    @media (min-width: 640px) {
      max-width: 80%;
    }

    @media (${p => p.theme.smMin}) {
      position: absolute;
      right: 24px;
      bottom: -45px;
      height: 270px;
    }

    @media (${p => p.theme.mdMin}) {
      right: 40px;
      bottom: -55px;
      height: 300px;
    }

    @media (${p => p.theme.lgMin}) {
      right: 15%;
      bottom: -80px;
      height: 375px;
    }
  }
`;

HeroStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeroStyled;
