import React from 'react';
import PropTypes from 'prop-types';

import RewardsListStyled from './RewardsList.style';

import TruncateText from '../TruncateText/TruncateText';

const RewardsList = ({ data, title }) => {
  const items = data.slides.map((item, index) => {
    return (
      <div
        className="RewardsList__item"
        title={item.title.props.defaultMessage}
        key={index.toString()}
      >
        <img src={item.src} alt={item.alt} />
        <div className="RewardsList__overlay">
          <div className="RewardsList__itemTitle">
            <TruncateText length={25} ellipsis>
              {item.title}
            </TruncateText>
          </div>
          {/*  <div className="RewardsList__itemNum">
            {index + 1} / {data.slides.length}
          </div> */}
        </div>
      </div>
    );
  });

  return (
    <RewardsListStyled>
      {title && (
        <div className="RewardsList__title">
          <h4 className="RewardsList__num">
            {data.title}
            <i />
            <i />
            <i />
            <i />
          </h4>
          <p className="RewardsList__desc --mobile">
            <TruncateText intlMessage={data.desc} length={100} />
          </p>
          <p className="RewardsList__desc --desktop">
            <TruncateText intlMessage={data.desc} />
          </p>
        </div>
      )}

      <div className="RewardsList__items">{items}</div>
    </RewardsListStyled>
  );
};

RewardsList.propTypes = {
  title: PropTypes.bool,
};

RewardsList.defaultProps = {
  title: true,
};

export default RewardsList;
