import React from 'react';

import SEO from '../components/seo';

import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import GrandPrize from '../components/GrandPrize/GrandPrize';
import Rewards from '../components/Rewards/Rewards';
import CollectorCans from '../components/CollectorCans/CollectorCans';
import Social from '../components/Social/Social';
import Footer from '../components/Footer/Footer';

const IndexPage = ({ location }) => (
  <Layout>
    <SEO title="Home" />
    <Header location={location} />
    <Hero />
    <GrandPrize />
    <Rewards />
    <CollectorCans />
    <Social />
    <Footer />
  </Layout>
);

export default IndexPage;
