import styled from 'styled-components';
import * as defaultTheme from '../variables';

import RewardsBg from '../../images/rewards-bg.jpg';

const RewardsStyled = styled.section`
  padding: ${p => p.theme.space * 6}px 0;
  color: ${p => p.theme.textColorWhite};
  background-image: url(${RewardsBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .Rewards__inner {
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;

    @media (${p => p.theme.smMin}) {
      padding: 0 ${p => p.theme.space * 2}px;
    }
  }

  .Rewards__header {
    margin-bottom: ${p => p.theme.space * 3}px;

    @media (${p => p.theme.smMin}) {
      display: flex;
      align-items: center;
      margin-bottom: ${p => p.theme.space * 6}px;
    }

    @media (${p => p.theme.lgMin}) {
      align-items: flex-end;
      margin-bottom: ${p => p.theme.space * 7}px;
    }
  }

  .Rewards__heading {
    flex-shrink: 0;
  }

  .Rewards__subtitle {
    padding: 20px;
    line-height: 14px;

    @media (${p => p.theme.smMin}) {
      max-width: 430px;
      margin-left: ${p => p.theme.space * 7}px;
      padding: 0;
      line-height: 24px;
    }
  }

  .Rewards__link {
    font-size: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

RewardsStyled.defaultProps = {
  theme: defaultTheme,
};

export default RewardsStyled;
