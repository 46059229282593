import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import TruncateTextStyled from './TruncateText.style';

class TruncateText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      truncated: true,
    };
  }

  componentDidMount() {
    const { truncated } = this.props;
    this.setState({ truncated });
  }

  handleToggle = () => {
    const { truncated } = this.state;
    this.setState({ truncated: !truncated });
  };

  render() {
    const { intl, intlMessage, children, length, ellipsis } = this.props;
    const { truncated } = this.state;

    let text;

    if (intlMessage && !children) {
      text = intl.formatMessage(intlMessage);
    } else {
      text = children;
    }

    const truncatedText =
      truncated && text.length > length ? text.substring(0, length) : text;

    const moreEl = truncated && text.length > length && (
      <span>
        ...&nbsp;
        <span className="TruncateText__more" onClick={this.handleToggle}>
          Show more
        </span>
      </span>
    );

    const ellipsisEl = ellipsis && text.length > length && (
      <span>...&nbsp;</span>
    );

    return (
      <TruncateTextStyled>
        {truncatedText}
        {ellipsis ? ellipsisEl : moreEl}
      </TruncateTextStyled>
    );
  }
}

TruncateText.propTypes = {
  intl: intlShape.isRequired,
  intlMessage: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  truncated: PropTypes.bool,
  ellipsis: PropTypes.bool,
  length: PropTypes.number,
};

TruncateText.defaultProps = {
  intlMessage: null,
  children: null,
  truncated: true,
  ellipsis: false,
  length: 200,
};

export default injectIntl(TruncateText);
