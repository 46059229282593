import imgRaptor1 from '../../images/4 - Raptor 1.jpg';
import imgRaptor2 from '../../images/5 - Raptor 2.jpg';
import imgRaptor3 from '../../images/6 - Raptor 3.jpg';
import imgRaptor4 from '../../images/7 - Raptor 4.jpg';
import imgRaptor5 from '../../images/8 - Raptor 5.jpg';
import imgRaptor6 from '../../images/9 - Raptor 6.jpg';
import videoRaptor6 from '../../images/raptor_edit_v3.mp4';

import imgRanger1 from '../../images/Ranger 1.jpg';
import imgRanger2 from '../../images/Ranger 2.jpg';
import imgRanger3 from '../../images/Ranger 3.jpg';
import imgRanger4 from '../../images/Ranger 4.jpg';
import imgRanger5 from '../../images/Ranger 5.jpg';

const config = {
  'en-US': {
    truckGallery: [
      {
        src: imgRaptor1,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor2,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor3,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor4,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor5,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      // {
      //   src: imgRaptor6,
      //   alt: '',
      //   lightboxSrc: videoRaptor6,
      //   // title: '2019 FORD F-150 RAPTOR',
      //   // desc: 'Gears 5 + Rockstar Energy Special Edition',
      // },
    ],
  },
  'en-CA': {
    truckGallery: [
      {
        src: imgRaptor1,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor2,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor3,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor4,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRaptor5,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      // {
      //   src: imgRaptor6,
      //   alt: '',
      //   lightboxSrc: videoRaptor6,
      //   // title: '2019 FORD F-150 RAPTOR',
      //   // desc: 'Gears 5 + Rockstar Energy Special Edition',
      // },
    ],
  },
  'en-GB': {
    truckGallery: [],
  },
  'de-DE': {
    truckGallery: [
      {
        src: imgRanger1,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRanger2,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRanger3,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRanger4,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
      {
        src: imgRanger5,
        alt: '',
        // title: '2019 FORD F-150 RAPTOR',
        // desc: 'Gears 5 + Rockstar Energy Special Edition',
      },
    ],
  },
  'es-MX': {
    truckGallery: [],
  },
};

export default config;
