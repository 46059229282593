import styled from 'styled-components';
import * as defaultTheme from '../variables';

const RewardsSliderStyled = styled.div`
  color: ${p => p.theme.textColorWhite};

  &:not(:last-child) {
    margin-bottom: ${p => p.theme.space * 3}px;
    padding-bottom: ${p => p.theme.space * 3}px;
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  }

  .RewardsSlider__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .RewardsSlider__title {
    display: flex;
    align-items: center;
    margin-bottom: ${p => p.theme.space * 2}px;
    padding: 0 20px;
  }

  .RewardsSlider__num {
    position: relative;
    margin: 0;

    span,
    p {
      display: block;
      margin: 0;
      padding: 4px 8px;
      font-weight: 700;
      line-height: 1.4;
      text-transform: uppercase;

      @media (${p => p.theme.smMin}) {
        padding: 4px 12px;
        font-size: 18px;
      }
    }

    p {
      white-space: nowrap;
    }

    i {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &:nth-child(2)::before,
      &:nth-child(2)::after {
        right: 0;
      }

      &:nth-child(3)::before,
      &:nth-child(3)::after {
        right: 0;
        bottom: 0;
      }

      &:nth-child(4)::before,
      &:nth-child(4)::after {
        bottom: 0;
      }

      &::before,
      &::after {
        position: absolute;
        content: '';
        background-color: #fff;
      }

      &::before {
        width: 11px;
        height: 1px;
      }

      &::after {
        width: 1px;
        height: 7px;
      }
    }
  }

  .RewardsSlider__desc {
    display: block;
    margin: 0;
    padding-left: ${p => p.theme.space * 2}px;
    line-height: 1.4;

    &.--mobile {
      @media (${p => p.theme.smMin}) {
        display: none;
      }
    }

    &.--desktop {
      @media (${p => p.theme.xsMax}) {
        display: none;
      }
    }
  }

  .RewardsSlider__sliderTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 21px;
    width: 100%;
    height: 28px;
    padding: 0 23px;
    font-family: 'AzbukaPro';
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    line-height: 12px;

    @media (${p => p.theme.smMin}) {
      bottom: 30px;
      padding: 0 32px;
      font-size: 13px;
    }

    @media (${p => p.theme.lgMin}) {
      bottom: 40px;
      padding: 0 45px;
    }
  }

  .RewardsSlider__sliderNum {
    position: absolute;
    bottom: 7px;
    width: 100%;
    font-size: 8px;
    font-weight: 500;
    text-align: center;

    @media (${p => p.theme.smMin}) {
      font-size: 12px;
    }

    @media (${p => p.theme.lgMin}) {
      bottom: 11px;
    }
  }
`;

RewardsSliderStyled.defaultProps = {
  theme: defaultTheme,
};

export default RewardsSliderStyled;
